'use strict';

const addSquareDialogTemplate = require('./addSquareDialog.html');
import { SquareService } from '../../core/dataservices/square.service';
import { HelperService } from '../../core/services/helper.service';
import { Pagination } from '../../core/models/pagination';
import { ProjectService } from '../../core/dataservices/project.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { ServerConstants } from '../../core/serverconstants';
import * as _ from 'lodash';
import { SearchService } from '../../core/dataservices/search.service';
import { InsitesUserDetailService } from '../../core/dataservices/insitesUserDetail.service';
import { AuthService } from '../../core/dataservices/auth.service';

export class ProgramSquaresController {
  static $inject = ['logger', 'squareservice', 'projectservice', 'helperservice', '$stateParams', '$q',
    '$mdDialog', 'spinnerservice', 'pinnedItemsService', 'serverConstants', 'searchservice', 'insitesUserDetailService', 'authService'];

  constructor(
    private logger,
    private squareservice: SquareService,
    private projectservice: ProjectService,
    private helperservice: HelperService,
    private $stateParams: ng.ui.IStateParamsService,
    private $q: ng.IQService,
    private $mdDialog: ng.material.IDialogService,
    private spinnerservice: SpinnerService,
    private pinnedItemsService,
    private serverConstants: ServerConstants,
    private searchService: SearchService,
    private insitesUserDetailService: InsitesUserDetailService,
    private authService: AuthService,
  ) { }

  squareAlreadyExistsMessage = 'Square with this name already exists.';
  squares = [];
  filter = '';
  squareStatusFilter = [this.serverConstants.squareStatusConstants.draft, this.serverConstants.squareStatusConstants.active,
    this.serverConstants.squareStatusConstants.scheduled];
  languages: { Name?} = {};
  newSquareName;
  squareType = this.serverConstants.searchTypeConstants.square;
  isSquareOnly = false;
  clientGuid;

  addSquareValidations = [
    {
      name: 'unique',
      isAsync: false,
      errorMessage: this.squareAlreadyExistsMessage,
      validate: (modelValue) => this.isSquareNameUnique(modelValue),
    },
    {
      name: 'uniqueForClient',
      isAsync: true,
      errorMessage: this.squareAlreadyExistsMessage,
      validate: (modelValue) => { // This could use a refactor to add a debounce so there isn't an API call done for every key input
        if (!modelValue) {
          return this.$q.resolve();
        }
        return this.squareservice.checkSquareNameIsDuplicate(modelValue).then((response) => {
          if (response.data.IsDuplicate) {
            return this.$q.reject();
          }
          return this.$q.resolve();

        });
      },
    },
  ];

  pagination: IPagination = new Pagination();

  loadingPromise;
  async $onInit() {
    this.isSquareOnly = this.authService.isSquareOnly();
    this.pagination.limit = 25;
    // Bind the promise to the data table so it can display a loading bar
    const languages = await this.projectservice.getLanguageList();
    this.languages = languages;
    this.logger.info('Languages loaded');
    this.loadingPromise = this.getProgramSquares();
    this.logger.info('Program squares activated');
    this.clientGuid = this.$stateParams.clientGuid;
  }

  async getProgramSquares() {
    return this.squareservice.getProgramSquares(this.$stateParams.programGuid).then((response) => {
      const data = response.data;
      _.each(data.List, (square: any) => {
        const language: any = _.find(this.languages, (item: any) => item.Code === square.Language);
        if (language) {
          square.LanguageName = (language as any).Name;
        } else {
          square.LanguageName = '';
        }
      });
      this.squares = data.List;
    });
  }

  getSquareStatusFormatAsString(format: number) {
    return _.findKey(this.serverConstants.squareStatusConstants, (c) => c === format);
  }
  search(filter) {
    const searchCallback = this.helperservice.search(filter, this.filter, false);
    return (item) => searchCallback(item) && this.squareStatusFilter.some((s) => s === item.Status);
  }

  async createSquare() {
    const dialogResponse = await this.$mdDialog.show({
      controller: 'AddSquareDialogController',
      controllerAs: 'vm',
      templateUrl: addSquareDialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
    });

    const createPromise =
      this.squareservice.createSquare(this.newSquareName, this.$stateParams.programGuid, dialogResponse.selectedSquareGuid);
    this.spinnerservice.showFor('loading', createPromise);
    const response = await createPromise;
    this.goToSquare(response.data);
  }


  notRemovable(status) {
    return status !== this.serverConstants.squareStatusConstants.draft &&
      status !== this.serverConstants.squareStatusConstants.scheduled;
  }

  deleteSquare(square) {
    // removed async because `no` raises an error
    this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Delete square')
        .text(`Are you sure you want to delete this Square ${square.Name}?`)
        .ok('Yes')
        .cancel('No')).then(async () => {
      if (square.Guid !== undefined && square.Guid !== this.serverConstants.validationConstants.emptyGuid) {
        try {
          this.spinnerservice.show('loading');
          await this.squareservice.deleteSquare(square.Guid);
          this.logger.success('Square successfully deleted');
          _.remove(this.squares, square);
        } catch (error) {
          if (error.status === 400) {
            const data = error.data;
            let errorMessage = '';
            _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
              errorMessage += `${validationError.ErrorMessage} `;
            });
            if (errorMessage.length > 0) {
              this.logger.error(errorMessage);
            }
          }
        } finally {
          this.spinnerservice.hide('loading');
          this.searchService.invalidate();
        }
      }
    }).catch(() => {
      // `no` was triggered
    });
  }

  async goToSquare(square) {
    if (square.Status !== this.serverConstants.squareStatusConstants.deleted) {
      const hasInSitesUserAccess = await this.insitesUserDetailService.hasInSitesUserAccess(this.$stateParams.clientGuid, square.Guid);
      if (!hasInSitesUserAccess) {
        return;
      }
      this.helperservice.goToEntity('square', square);
    }
  }

  showArchivedButton(square) {
    return this.authService.isHuman8UserLogin() && square.ShowArchivedButton;
  }

  isSquareNameUnique(name) {
    const uniqueSquares = this.squares.filter((square) =>
      square.Name.toLowerCase() === name.toLowerCase() &&
      square.Status !== this.serverConstants.squareStatusConstants.deleted,
    );

    return uniqueSquares.length === 0;
  }

  isSquareDeleted(square) {
    return square.Status === this.serverConstants.squareStatusConstants.deleted;
  }

  isPinned(square) {
    return this.pinnedItemsService.isPinned(square.Guid);
  }

  togglePin(square) {
    this.pinnedItemsService.toggleSquarePin(square.Guid);
  }
}
