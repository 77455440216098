'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';
import { TransitionService } from '@uirouter/angularjs';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { ServerConstants } from 'src/app/core/serverconstants';

const routeConfig = (
  routerHelper: RouterHelper,
  serverConstants: ServerConstants,
  authService: AuthService,
  $transitions: TransitionService,
  featureService: FeatureService,
  __env: Environment,
) => {
  routerHelper.configureStates([
    {
      state: 'root.square.pulse',
      config: {
        url: '/activity/:activityGuid/Pulse',
        title: 'Pulse',
        settings: {
          nav: 4,
          section: 'ACTIVITY MANAGEMENT',
          svgIcon: 'images/PulseSmall.svg#pulse|0 0 512 512',
          label: 'Pulse',
          show: async () => await featureService.isMasterFeatureEnabledForSquare(serverConstants.featureConstants.pulse),
        },
      },
    },
  ]);

  $transitions.onBefore({ to: 'root.square.pulse' }, (transition) => {
    const { activityGuid, squareGuid, clientGuid, programGuid } = transition.params();
    const url = new URL(__env.pulseUrl);
    url.searchParams.set('clientGuid', clientGuid);
    url.searchParams.set('programGuid', programGuid);
    url.searchParams.set('platformGuid', squareGuid);
    url.searchParams.set('activityGuid', activityGuid);
    url.searchParams.set('token', authService.getToken());
    window.open(url);
    return false;
  });
};

routeConfig.$inject = [
  'routerHelper',
  'serverConstants',
  'authService',
  '$transitions',
  'featureservice',
  '__env'];

angular
  .module('insitesApp.iscPulse', [])
  .run(routeConfig);
