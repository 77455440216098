'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from './../../../../../../core/serverconstants';
import { ICommunication } from './../../../../../../core/dataservices/dataservice.contracts.d';
import { CommunicationChannelController } from './../communicationChannel.controller';
import { ActivityService } from './../../../../../../core/dataservices/activity.service';
import { DateFormatService } from './../../../../../../core/services/dateformat.service';
import { CommunicationService } from '../../../../../../core/dataservices/communication.service';
import { SequencedActivityInfoMessageHelper } from '../../../sequencedActivity/sequencedActivityInfoMessageHelper';
import { IFooter } from './footer';
import * as _ from 'lodash';
import { SpinnerService } from '../../../../../../core/services/spinner.service';

export class ActivityEmailController {
  static $inject = ['$stateParams', 'dateFormatService', 'serverConstants', 'communicationservice',
    'activityservice', 'logger', 'spinnerservice'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private dateFormatService: DateFormatService,
    private serverConstants: ServerConstants,
    private communicationservice: CommunicationService,
    private activityservice: ActivityService,
    private logger: Logger,
    private spinnerService: SpinnerService,
  ) {}

  // Footers vars
  DefaultFooter: string;
  defaultFooterIsSelected;
  footers: IFooter[] = [];
  SaveAsTemplate;
  footerMode;
  footerModes = {
    Save: 'existing',
    New: 'new',
  };
  selectedFooter: IFooter;
  newFooterText = false;
  // Common vars
  wizardStep;
  activityOffset;
  currentTimeZone = DateTime.local().offset / 60;
  removeLink;
  // Channel specific vars
  form;
  startDateActivity: DateTime;
  endDateActivity;
  startDateTimeActivity;
  endDateTimeActivity;
  channelType = this.serverConstants.channelTypeConstants.researchActivityEmail;
  validationConstants = this.serverConstants.validationConstants;
  model: ICommunication = {
    Guid: '',
    Title: '',
    Message: '',
    StartDateTime: DateTime.now(),
    StartTime: DateTime.now(),
    EndDateTime: DateTime.now(),
    StartDateOffset: this.currentTimeZone,
    CallToActionText: '',
    Rewards: [],
    FooterText: '',
    NewFooter: {},
    FooterName: '',
    PublishOption: null,
    SoftLaunchNumber: null,
  };
  communication;
  prefixLabel;
  suffixLabel;
  isReadOnly;
  footerReadOnly;
  saveNotApplicable = false;
  saveCallback = () => this.saveData(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm(this);
  infoTextProvider: SequencedActivityInfoMessageHelper;
  minStartDate: DateTime;

  get saveFooterText() {
    return !this.newFooterText && this.defaultFooterIsSelected ? null : _.escape(this.model.FooterText);
  }

  async $onInit() {
    this.removeLink = this.wizardStep.linkComponent(`channel-${this.channelType}`, this);
    const result = await this.wizardStep.resolveComponent(['channels']);
    const channelsStep = result[0];
    this.infoTextProvider = new SequencedActivityInfoMessageHelper(this.serverConstants, channelsStep);
    await this.loadFooter();
    this.$loadData(this);
  }

  async loadFooter() {
    // Load footers in list
    this.DefaultFooter = await this.communicationservice.getCommunicationDefaultFooter();
    this.footerMode = this.footerModes.Save;
    const communicationFootersCurrentSquare = await this.communicationservice.getCommunicationFooters();
    const defaultFooterModel: IFooter = {
      Id: null,
      Guid: null,
      Name: 'default',
      FooterText: this.DefaultFooter,
    };
    this.footers = [defaultFooterModel].concat(
      communicationFootersCurrentSquare.Footers.map((f): IFooter => ({ Id: f.Id, Guid: f.Guid, Name: f.Name, FooterText: f.FooterText })),
    );

    // Sets default footer if not in edit mode
    if (this.model.FooterText === '') {
      // Sets the footer to the default footer
      this.selectedFooter = this.footers[0];
      this.model.FooterText = this.selectedFooter ? this.selectedFooter.FooterText : '';
      this.defaultFooterIsSelected = true;
    } else {
      // Populate selected footer with the one that matches the footer text for this communication
      this.selectedFooter = _.find(this.footers, (f) => f.FooterText === this.model.FooterText);
    }
  }

  async $wizardStepLoaded() {
    this.spinnerService.show('loading');
    this.$loadData(this);
    const channelsStep: CommunicationChannelController = await this.wizardStep.resolveComponent('channels');
    const selectedResearchActivityGuid = channelsStep.communication.Communication.ActivityGuid;

    const response = await this.activityservice.getActivityDetailsForMessage(selectedResearchActivityGuid);
    const activityDetails = response.data.Detail;
    const activityStartDate = this.dateFormatService.getDateTime(activityDetails.StartDate, activityDetails.Offset);
    const activityEndDate = this.dateFormatService.getDateTime(activityDetails.EndDate, activityDetails.Offset);

    // Used for validation
    this.startDateTimeActivity = activityStartDate;
    this.endDateTimeActivity = activityEndDate;

    // Used for min date; time must be midnight for the date validation to work
    this.startDateActivity = this.dateFormatService.startOfDay(activityStartDate);
    this.endDateActivity = this.dateFormatService.endOfDay(activityEndDate);

    this.activityOffset = activityDetails.Offset;
    // ZERO is treated as invalid
    if (this.model.StartDateOffset === undefined
      || this.model.StartDateOffset === null) {
      this.model.StartDateOffset = this.activityOffset || this.currentTimeZone;
    }

    if (!this.model.StartDateTime) {
      this.model.StartDateTime = this.dateFormatService.getDateTime(activityDetails.StartDate, this.model.StartDateOffset);
    }

    const currentDate = this.dateFormatService.startOfDay();
    this.minStartDate = DateTime.max(...[this.startDateActivity, currentDate]);

    this.spinnerService.hide('loading');
  }

  resetForm(root) {
    if (!root) {
      root = this;
    }
    this.$loadData(root);
    this.form.$setPristine();
  }

  timeChanged() {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
    }
    if (this.model.StartDateTime && this.startDateTimeActivity) {
      this.model.StartDateTime = this.model.StartDateTime.set({ second: this.startDateTimeActivity.second });
      const startDate = this.dateFormatService.getDateTime(this.model.StartDateTime, this.model.StartDateOffset);
      const startDateActivity = this.dateFormatService.getDateTime(this.startDateTimeActivity, this.activityOffset);
      this.form.startTime.$setValidity('minHour', startDate >= startDateActivity);
      const dateTimeNow = this.dateFormatService.getDateTime(this.dateFormatService.convertDate(), 0);
      const communicationStartDateTime = this.dateFormatService.getDateTime(this.model.StartDateTime, this.model.StartDateOffset);
      this.form.startTime.$setValidity('dateInPast', communicationStartDateTime > dateTimeNow);

      this.form.startTime.$setValidity('maxHour', communicationStartDateTime < DateTime.fromISO(this.endDateTimeActivity));
      // after properly setting up the start date with offset, apply it to model too
      this.model.StartDateTime = startDate;
    }
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardStepIsCompleted(): boolean {
    const channel = _.find(
      this.communication.Channels,
      (communicationChannel: any) => communicationChannel.Channel.ChannelType === this.serverConstants.channelTypeConstants.researchActivityEmail,
    );

    if (channel !== undefined && channel.Channel.Title && channel.Channel.Message && channel.Channel.CallToActionText) {
      return true;
    }

    return false;
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    if (this.isReadOnly) {
      return '';
    }

    if (_.every(this.communication.Channels, (c: any) => c.Channel.IsPublished)) {
      return '';
    }
    if (_.every(this.communication.Channels, (c: any) => !c.Channel.IsPublished)) {
      return this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }

    const draftChannels = _.filter(this.communication.Channels, (c: any) => !c.Channel.IsPublished);

    const mailChannels = {};
    mailChannels[this.serverConstants.channelTypeConstants.researchActivityReminder.toString()] = 'Activity reminder email';
    mailChannels[this.serverConstants.channelTypeConstants.researchActivityIncomplete.toString()] = 'Activity incomplete email';

    return `${_.map(draftChannels, (c) => mailChannels[c.Channel.ChannelType.toString()])
      .join(',')
      .replace(/,(?=[^,]*$)/, ' and ')} ${this.serverConstants.squareConstants.wizardNotPublishedStatus.toLowerCase()}`;
  }

  $wizardIsValid() {
    return this.form ? this.form.$valid : true;
  }

  $wizardBeforeNext() {
    this.saveData(this);
  }

  public get StartDateOffsetWatch() {
    return this.model.StartDateOffset;
  }

  public set StartDateOffsetWatch(newItem: any) {
    this.timeChanged();
    this.model.StartDateOffset = newItem;
  }

  $postLink() {
    setTimeout(() => {
      this.form.StartDateTime.$setValidity('required', true);
    }, 0);
  }

  footerTextChanged() {
    this.newFooterText = true;
  }

  footerSelected(value) {
    this.defaultFooterIsSelected = !value.Id;
    this.model.FooterText = value.FooterText;
    this.newFooterText = false;
    this.SaveAsTemplate = false;
    this.model.FooterName = value.Name;
  }

  footerModeChanged() {
    if (this.footerMode === 'existing') {
      this.selectedFooter = null;
      this.model.FooterName = '';
      this.SaveAsTemplate = false;
      this.newFooterText = true;
    }
    if (this.footerMode === 'new') {
      this.selectedFooter = this.footers[0];
      if (this.selectedFooter) {
        this.footerSelected(this.selectedFooter);
      }
    }
  }

  setFooterText(footerText: string): void {
    this.model.FooterText = footerText || '';

    if (this.model.FooterText) {
      const result = this.footers.find((x) => x.FooterText === this.model.FooterText);
      if (result) {
        this.footerMode = 'new';
        this.selectedFooter = result;
        this.footerMode = 'existing';
      } else {
        this.footerMode = 'existing';
        this.footerModeChanged();
        this.footerMode = 'new';
      }
    }
  }

  async saveData(root: this) {
    let newFooter = null;
    if (root.SaveAsTemplate) {
      newFooter = {
        Name: root.model.FooterName,
        FooterText: _.escape(root.model.FooterText),
      };
    }
    const channel = {
      Guid: root.model.Guid,
      Title: root.model.Title,
      CommunicationGuid: root.communication.Communication.Guid,
      ChannelType: root.serverConstants.channelTypeConstants.researchActivityEmail,
      Message: _.escape(root.model.Message),
      CallToActionText: root.model.CallToActionText,
      Rewards: root.model.Rewards,
      StartDateTime: root.dateFormatService.getDateTimeForSaving(root.model.StartDateTime, root.model.StartDateOffset),
      StartDateOffset: root.model.StartDateOffset,
      FooterText: root.saveFooterText,
      NewFooter: newFooter,
    };
    root.form.$setPristine();
    await root.communicationservice.updateActivityEmail(root.$stateParams.communicationGuid, channel).then(
      () => {
        root.communicationservice.selectCommunication(root.$stateParams.communicationGuid).then((response) => {
          root.communication = response;
          root.$loadData(root);
        });
        this.addNewTemplateToTemplateList(newFooter);
        if (!root.isReadOnly) {
          root.logger.success('Communication message successfully updated.');
        }
      },
      () => {
        root.logger.error('Communication message not successfully updated.');
      },
    );
  }
  addNewTemplateToTemplateList(footer: any): void {
    if (footer) {
      this.footers.push({ Id: null, Guid: null, Name: footer.Name, FooterText: footer.FooterText });
    }
  }

  $loadData(root: this) {
    if (!root) {
      root = this;
    }
    const channel = _.find(
      root.communication.Channels,
      (communicationChannel: any) => communicationChannel.Channel.ChannelType === root.serverConstants.channelTypeConstants.researchActivityEmail,
    );
    if (channel !== undefined) {
      root.prefixLabel = channel.ChannelPrefixTemplate;
      root.suffixLabel = channel.ChannelSuffixTemplate;
      root.model.Guid = channel.Channel.Guid;
      root.model.Title = channel.Channel.Title ? channel.Channel.Title : root.communication.Communication.Title;
      root.channelType = channel.Channel.ChannelType;
      root.model.Message = channel.Channel.Message;
      root.model.CallToActionText = channel.Channel.CallToActionText;
      // Update it when rewards are saved
      root.model.Rewards = [];
      // ZERO is treated as invalid
      if (channel.Channel.StartDateTime !== undefined
        && channel.Channel.StartDateOffset !== undefined) {
        // If the channel has been published already OR the startdate is in the future,
        // Show the date that was configured before.
        // Otherwise (if the channel hasn't been published yet AND the startdate of the channel is now in the past),
        // Fill in the current date.
        root.model.StartDateTime = root.dateFormatService.getDateTime(
          (channel.Channel.IsPublished || DateTime.fromISO(channel.Channel.StartDateTime) > DateTime.now())
            ? channel.Channel.StartDateTime
            : DateTime.now(),
          channel.Channel.StartDateOffset,
        );
        root.model.StartTime = root.model.StartDateTime;
        root.model.StartDateOffset = channel.Channel.StartDateOffset;
      }
      root.setFooterText(channel.Channel.FooterText);
      root.model.PublishOption = channel.Channel.PublishOption;
      root.model.SoftLaunchNumber = channel.Channel.SoftLaunchNumber;
      root.saveNotApplicable = channel.Channel.IsPublished;
    }
  }
}
