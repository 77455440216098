'use strict';

import { DateTime } from 'luxon';
import { ActivityService } from './../../../../core/dataservices/activity.service';
import { DateFormatService } from './../../../../core/services/dateformat.service';
import { ServerConstants } from '../../../../core/serverconstants';
import { equals } from 'angular';
import { FeatureService } from '../../../../core/dataservices/feature.service';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
import { SegmentationService } from '../../../../core/dataservices/segmentation.service';
import { ActivityObservationSpecifyModel } from './ActivityObservationSpecifyModel';
import { DiscussionDataService } from 'src/app/core/services/discussionData.service';
import { MappingService } from 'src/app/core/services/mapping.service';
import { ActivityQualSpecifyControllerBase } from '../common/activityQualSpecifyControllerBase';

export class ActivityObservationSpecifyController extends ActivityQualSpecifyControllerBase {
  static $inject = [
    '$mdDialog', '$stateParams', 'activityservice', 'serverConstants', 'dateFormatService', '_', '$q',
    'logger', 'featureservice', 'poboService', 'segmentationService', 'discussionDataService', '$scope',
    'mappingService',
  ];

  constructor(
    protected $mdDialog: ng.material.IDialogService,
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    serverConstants: ServerConstants,
    dateFormatService: DateFormatService,
    private _: _.LoDashStatic,
    private $q: ng.IQService,
    private logger: Logger,
    private featureservice: FeatureService,
    private poboService: PoboService,
    private segmentationService: SegmentationService,
    private discussionDataService: DiscussionDataService,
    private $scope: ng.IScope,
    private mappingService: MappingService,
  ) {
    super($mdDialog, dateFormatService, serverConstants);
    this.validationConstants = serverConstants.validationConstants;
    this.activityTypes = serverConstants.squareActivityTypeConstants;
    this.activityContextTypeConstants = serverConstants.activityContextTypeConstants;
  }

  validationConstants;
  activityTypes;
  activityContextTypeConstants;
  isDraftOrScheduled = false;
  removeLink;
  minStartDate;
  minEndDate;
  allowStartDateEdit = false;
  allowEndDateEdit = true;
  currentTimeZone = DateTime.local().offset / 60;
  wizardStep;
  squareActivity: SquareActivityModel;
  form;
  model: ActivityObservationSpecifyModel;
  initialStatus;
  initialContributionType?: number = null;
  isPublished;
  isActivityTargetCompletionEnabled;
  timespan = {
    isValid: true,
    text: '',
  };
  isReadOnly;
  saveNotApplicable = false;
  isSavingUpReward;
  saveCallback = async () => await this.saveData();
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  customValidation = () => this.validateNavigation('', '', this);
  resetFormCallback = () => this.resetForm();
  usesArchivedSegmentations: boolean;
  originalVisibility = undefined;
  uninfluencedPlaceHolder = this.serverConstants.activityVisibilityTypeConstants.uninfluenced;

  // Overwrite the Context defined in the inherited class
  Context = [{
    Label: 'Group', // the public context type should be displayed as Group in the Observation activity type
    Value: this.serverConstants.activityContextTypeConstants.public,
  },
  {
    Label: 'Private',
    Value: this.serverConstants.activityContextTypeConstants.private,
  },
  ];

  async $onInit() {
    this.isDraftOrScheduled = this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.scheduled
      || this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft;

    this.isActivityTargetCompletionEnabled = await this.featureservice.checkFeatureAccessibilityForSetup(
      this.serverConstants.featureConstants.activityTargetCompletion);

    this.removeLink = this.wizardStep.linkComponent('specifyQual', this);
    this.resetForm();
    this.allowStartDateEdit = this.squareActivity.Detail.CanChangeStartDate;
    this.initialStatus = angular.copy(this.squareActivity.Detail.Status);
    this.saveNotApplicable = this.squareActivity.ActivityQualDetail ? this.squareActivity.ActivityQualDetail.IsPublished : false;

    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.closed) {
      return this.segmentationService.getSquareSegmentations(true, true, true, this.squareActivity.Detail.Guid).then((segmentations) => {
        this.usesArchivedSegmentations = this._.some(segmentations, (e) => e.IsArchived);
      });
    }
    this.model.StartTime = this.model.StartDateTime;
    this.model.EndTime = this.model.EndDateTime;
  }

  onContextChange(value) {
    switch (value) {
      case this.serverConstants.activityContextTypeConstants.public:
        this.model.Visibility = this.Visibility[1].Value;
        // When the context is public, the ContributionType won't be visible, so we set it to its default value
        this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.standard;
        break;
      case this.serverConstants.activityContextTypeConstants.private:
        this.model.Visibility = this.serverConstants.activityVisibilityTypeConstants.private;
        // When the context is private, the ContributionType should be Multiple by default for an Observation activity
        this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.diary;
        break;
    }
    this.form.Context.$setValidity('serverErrors', true);
  }

  private setEndTimeInit() {
    if (this.squareActivity.Detail.EndDate) {
      this.model.EndDateTime = this.dateFormatService.getDateTime(this.squareActivity.Detail.EndDate, this.model.DateOffset);
    }
  }

  private setThemeInit() {
    if (this.squareActivity.Detail.ThemeGuid) {
      this.model.Theme = this.squareActivity.Detail.ThemeGuid;
    }
  }

  private populateModelDefaultTimesInit() {
    this.minStartDate = this.dateFormatService.startOfDay();
    this.minEndDate = DateTime.fromISO(this.minStartDate);
    const defaultEndDate = this.squareActivity.Detail.EndDate ? DateTime.fromISO(this.squareActivity.Detail.EndDate) : this.minEndDate.plus({ days: 7 });
    this.model = new ActivityObservationSpecifyModel(DateTime.now(), defaultEndDate, this.currentTimeZone, () => this.onTimeZoneChanged());
  }

  private mapDatesInit() {
    if (this.squareActivity.Detail.Offset) {
      this.model.DateOffset = this.squareActivity.Detail.Offset;
    }
    this.model.StartDateTime = this.dateFormatService.getDateTime(this.squareActivity.Detail.StartDate, this.model.DateOffset);
    this.minStartDate = this.dateFormatService.getMinDate(this.minStartDate, this.model.StartDateTime);
    this.minEndDate = this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.closed ?
      DateTime.now() :
      this.minEndDate || this.minStartDate;
  }

  $wizardStepIsCompleted() {
    return this.squareActivity !== undefined && this.squareActivity.ActivityQualDetail !== undefined;
  }

  $wizardIsValid() {
    // Make CompletionTarget empty when the checkbox is unticked
    this.model.CompletionTarget = this.model.IsCompletionTarget ? this.model.CompletionTarget : null;
    if (!this.isActivityTargetCompletionEnabled && this.model.IsCompletionTarget && (this.model.CompletionTarget == null ||
      this.model.CompletionTarget < 0 ||
      this.model.CompletionTarget > this.serverConstants.validationConstants.integerMaxValue)) {
      return false;
    }

    if (this.isSavingUpReward && this.model.CompletionPoints
      && ((this.model.CompletionPoints.Qualified < 0 || this.model.CompletionPoints.Qualified > this.serverConstants.validationConstants.integerMaxValue)
        || (this.model.CompletionPoints.QuotaFull < 0 || this.model.CompletionPoints.QuotaFull > this.serverConstants.validationConstants.integerMaxValue)
        || (this.model.CompletionPoints.ScreenedOut < 0 || this.model.CompletionPoints.ScreenedOut > this.serverConstants.validationConstants.integerMaxValue))) {
      return false;
    }

    return this.form.$valid;
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    let description = '';
    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  calculateTimespanValidity() {
    if (this.model.StartDateTime && this.model.EndDateTime) {
      const end = this.model.EndDateTime.startOf('minute');
      const start = this.model.StartDateTime.startOf('minute');
      const min = end.diff(start).as('minutes');
      if (min < 0) {
        this.timespan = {
          isValid: false,
          text: 'End date ends before start date.',
        };
      } else if (min === 0) {
        this.timespan = {
          isValid: false,
          text: 'The start and end time are the same.',
        };
      } else {
        // The reason to also include 'minutes' in the diff method is to make sure that hours is a whole number
        const { days, hours } = end.diff(start, ['days', 'hours', 'minutes']).toObject();
        this.timespan = {
          isValid: true,
          text: `${(days > 0 ? (`${days} days and `) : '') + hours} hours.`,
        };
      }
    }
  }

  onStartDateChanged() {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
      this.model.StartTime = this.model.StartDateTime;
    }
    this.form.StartDate.$setValidity('serverErrors', true);
    this.minEndDate = this.dateFormatService.startOfDay(this.model.StartDateTime);
    this.calculateTimespanValidity();
  }

  onEndDateChanged() {
    if (this.model.EndTime) {
      this.model.EndDateTime = this.model.EndDateTime.set({ hour: this.model.EndTime.hour, minute: this.model.EndTime.minute });
      this.model.EndTime = this.model.EndDateTime;
    }
    this.form.EndDate.$setValidity('serverErrors', true);
    this.calculateTimespanValidity();
    this.isReadOnly = false;
    if (this.model.EndDateTime) {
      if (this.model.EndDateTime.diff(DateTime.utc()).as('days') >= 0
        && this.initialStatus === this.serverConstants.squareActivityStatusConstants.closed) {
        this.squareActivity.Detail.Status = this.serverConstants.squareActivityStatusConstants.active;
      } else {
        this.squareActivity.Detail.Status = this.initialStatus;
      }
    }
  }

  onTimeZoneChanged() {
    this.calculateTimespanValidity();
  }

  async $wizardBeforeNext() {
    let ok = this.squareActivity.Detail.Status !== this.serverConstants.squareActivityStatusConstants.closed &&
      this.squareActivity.Detail.Status !== this.serverConstants.squareActivityStatusConstants.active;
    const visibilityChanged = this.originalVisibility !== undefined && !equals(this.originalVisibility, this.model.Visibility);
    const unarchiveSegmentation = this.usesArchivedSegmentations;
    const jobIdWillBePrefilled = this._.isNull(this.squareActivity.Detail.SquareJobId)
      && this.initialStatus === this.serverConstants.squareActivityStatusConstants.closed
      && this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.active
      && this.isSavingUpReward;
    if (!ok && (visibilityChanged || unarchiveSegmentation || jobIdWillBePrefilled)) {
      await this.$mdDialog.show(
        this.$mdDialog.iscConfirm()
          .title('Activity configuration')
          .text(`Changes might impact:\n\n${visibilityChanged ? '    - the discussion visibility (please consider to inform the participants' +
            'about this in the opening post) \n' : ''
          }${unarchiveSegmentation ? '    - reopening this activity will unarchive segmentations used by it\n' : ''
          }${jobIdWillBePrefilled ? '    -  As Saving up is enabled, a JobID needs to be assigned to this activity.' +
              ' By default, this will be updated to the latest JobID available on the Square info page. Please check,' +
              ' and adapt if needed, via Activity info page. ' : ''
          }\nAre you sure you want to proceed?`)
          .ok('Accept changes')
          .cancel('Cancel'),
      ).then(
        () => {
          ok = true;
        });
    } else {
      ok = true;
    }

    if (ok) {
      if (this.squareActivity.ActivityQualDetail?.IsPublished === true) {
        const username = await this.poboService.checkPoboConfirmation(
          this.squareActivity.ActivityQualDetail.OpenPostUsername, !this.squareActivity.ActivityQualDetail.Title);
        this.squareActivity.ActivityQualDetail.OpenPostUsername = username;
      }
      await this.saveWithParentActivityChangesConfirmation();
    }
    this.$q.reject();
  }

  validateNavigation(toParams, fromParams, root) {
    if (!root) {
      root = this;
    }
    return {
      canNavigate: root.form.$valid,
      isDirty: root.form.$dirty,
    };
  }

  protected getSquareActivity() {
    return this.squareActivity;
  }

  protected getModel() {
    return this.model;
  }

  protected async saveData() {
    const dataToSend = {
      ActivityGuid: this.$stateParams.activityGuid,
      Context: this.model.Context,
      ThemeGuid: this.model.Theme,
      Visibility: this.model.Visibility,
      CompletionPoints: this.model.CompletionPoints,
      StartDate: this.dateFormatService.getDateTimeForSaving(this.model.StartDateTime, this.model.DateOffset),
      EndDate: this.dateFormatService.getDateTimeForSaving(this.model.EndDateTime, this.model.DateOffset),
      CompletionTarget: this.model.IsCompletionTarget ? this.model.CompletionTarget : null,
      Offset: this.model.DateOffset,
      ContributionType: this.model.ContributionType,
    };

    try {
      // we need to call an endpoint in the monolith to save Activity properties, emit events, etc.
      await this.activityservice.updateActivityQualSpecifyForDividedTypes(dataToSend);
      // we also need to call endpoints in the Discussions module in order to save everything related to Discussion and DiscussionActivity entities
      const discussionGuid = await this.discussionDataService.createOrUpdateDiscussionActivity(
        this.squareActivity.discussionModel?.discussionGuid, this.$stateParams.activityGuid,
        this.model.Visibility, this.model.ContributionType, this.squareActivity.discussionModel?.title ?? this.squareActivity.Detail.ActivityName,
        this.squareActivity.discussionModel?.isOneByOne);

      // since the flow is somewhat different for new discussions and only at this point have we created the minimum DB objects we reload the model
      // we also need to do this before setting the discussionModel below since that is not part of the backend response
      if (this.squareActivity.ActivityQualDetail == null) {
        const response = await this.activityservice.selectActivityQualResearch(this.$stateParams.activityGuid);
        this.squareActivity = response.data;
      }

      // isOneByOne should be checked by default when contributionType is not diary and visibility is not influenced
      const isOneByOne = this.model.ContributionType !== this.serverConstants.activityContributionTypeConstants.diary
        && this.model.Visibility !== this.serverConstants.activityVisibilityTypeConstants.influenced;
      if (!this.squareActivity.discussionModel) {
        // the first time we create the discussion activity
        this.squareActivity.discussionModel = {
          discussionGuid,
          activityGuid: this.$stateParams.activityGuid,
          contributionType: this.model.ContributionType,
          visibilityType: this.model.Visibility,
          appearance: this.serverConstants.appearanceConstants.centered,
          post: null,
          isOneByOne,
          title: null,
          isAnswered: false,
          caption: '',
        };
      } else {
        // update the properties that were saved in the Observation specify step
        this.squareActivity.discussionModel.contributionType = this.model.ContributionType;

        if (!this.squareActivity.discussionModel.post) {
          // the opening post is not yet created, set the default value
          this.squareActivity.discussionModel.isOneByOne = isOneByOne;
        } else if (this.model.ContributionType === this.serverConstants.activityContributionTypeConstants.diary) {
          // if there was already a value saved for OneByOne and the contribution type is diary,
          // set OneByOne to false (it won't be displayed, so make sure we save it as default)
          this.squareActivity.discussionModel.isOneByOne = false;
        }
      }
      this.squareActivity = this.mappingService.mapDiscussionActivityDetailsToSquareActivityModel(this.squareActivity.discussionModel, this.squareActivity);

      this.form.$setPristine();
      this.squareActivity.discussionModel.visibilityType = this.model.Visibility;
      this.squareActivity.ActivityQualDetail.Visibility = this.model.Visibility;
      this.originalVisibility = this.model.Visibility;
      this.model.StartTime = this.model.StartDateTime;
      this.model.EndTime = this.model.EndDateTime;
      this.$scope.$apply(); // used to run the digest cycle as soon as the backend calls end (not sure why it doesn't happen fast enough without calling it)
      this.logger.success('Activity specifications successfully updated');
    } catch (error) {
      if (error.status === 400) {
        const errorData = error.data;
        // Group by property name in case there is more than 1 error for that property
        // Ideally we should already group them in the backend
        const grouped = this._.groupBy(errorData.ValidationErrors, 'PropertyName');
        this._.forEach(grouped,
          (item, key) => {
            let message = '';
            this._.forEach(item,
              (errorMessage) => {
                const errorItem: any = errorMessage;
                message += `${errorItem.ErrorMessage} `;
              });
            try {
              this.form[key].$setDirty();
              this.form[key].$setValidity('serverErrors', false);
              this.form[key].errorMessage = message;
            } catch (e) {
              this.logger.error('Activity specifications could not be saved');
            }
          });
      }
      return this.$q.reject(error);
    }
  }

  protected isReadOnlyActivity(): boolean {
    return this.isReadOnly;
  }

  resetForm() {
    this.populateModelDefaultTimesInit();
    this.mapDatesInit();
    this.setEndTimeInit();
    this.setThemeInit();
    this.calculateTimespanValidity();
    if (!this.squareActivity) {
      return;
    }

    if (this.isSavingUpReward) {
      this.model.CompletionPoints = {
        Qualified: this.squareActivity.Detail.CompletionPoints,
        ScreenedOut: this.squareActivity.Detail.CompletionPointsScreenedOut,
        QuotaFull: this.squareActivity.Detail.CompletionPointsQuotaFull,
      };
    }

    // In drafts, is true by default, but not needed when feature is toggled on
    this.model.IsCompletionTarget = this.squareActivity.Detail.IsCompletionTarget;
    this.model.CompletionTarget = this.squareActivity.Detail.CompletionTarget;

    if (!this.squareActivity.ActivityQualDetail) {
      // default values
      this.model.Context = this.Context[1].Value;
      this.model.Visibility = this.originalVisibility = this.serverConstants.activityVisibilityTypeConstants.private;
      if (this.model.Context === this.serverConstants.activityContextTypeConstants.private) {
        this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.diary;
      }
    } else {
      this.model.Visibility =
        this.originalVisibility =
        this.squareActivity.ActivityQualDetail.Visibility;
      this.model.Context = this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.private
        ? this.serverConstants.activityContextTypeConstants.private
        : this.serverConstants.activityContextTypeConstants.public;

      if (this.squareActivity.ActivityQualDetail.ContributionType !== undefined && this.squareActivity.ActivityQualDetail.ContributionType !== null) {
        this.model.ContributionType = this.squareActivity.ActivityQualDetail.ContributionType;
        this.initialContributionType = angular.copy(this.squareActivity.ActivityQualDetail.ContributionType);
      } else if (this.model.Context === this.serverConstants.activityContextTypeConstants.private) {
        this.model.ContributionType = this.serverConstants.activityContributionTypeConstants.diary; // Set default value
      }

      this.form.$setPristine();
    }
  }

  isLowerThanCompletionTargetLimit() {
    return (this.model != null && this.model.CompletionTarget != null
      && ((this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch
        && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.forumDiscussionMin)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch
          && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.privateDiscussionMin)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateScoutResearch
          && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.lifeScenesMin)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicScoutResearch
          && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.lifeSnapshotsMin)));
  }

  isHigherThanCompletionTargetLimit() {
    return (this.model != null && this.model.CompletionTarget != null
      && ((this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch
        && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.forumDiscussionMax)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch
          && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.privateDiscussionMax)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateScoutResearch
          && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.lifeScenesMax)
        || (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicScoutResearch
          && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.lifeSnapshotsMax)));
  }

  get showParticipantContributions() {
    return this.model
      ? this.model.Context === this.serverConstants.activityContextTypeConstants.private
      : false;
  }
}
