'use strict';

import {
  squareService,
  SaveSplashMessageRequest,
  ClientWithSquaresResponse,
} from '@insites/vue-eco-temp-library';
import { PascalCase, Utils } from '../utils';
import { HttpService } from './http.service';
import {
  ActivityThemeListItemResponse,
  CheckSquareNameIsDuplicateResponse,
  ConnectProjectDetailItem,
  CountryResponse,
  CreateSquareResponse,
  GetSquareActivationQuestionsResponse,
  GetSquareJobsResponse,
  ImpersonateUserListResponse,
  ListJobItemResponse,
  ListSquareItemResponse,
  SaveSquareActivationQuestionsRequest,
  SaveSquareColorsRequest,
  SplashMessageDetailItem,
  SquareDesignDetailItemResponse,
  SquareDetailItemResponse,
  SquareLandingPageConfigResponse,
  SquareParticipantForLoggedInInSitesUserResponse,
  SquareSelectionResponse,
  SquareSocialLoginConfigResponse,
  SyncPageResponse,
  UpdateProgramSquareJobsResponse,
  UpdateSquare,
  UpdateSquareRequest,
  UpdateSquareResponse,
  UserMigrationConfigResponse,
} from './square.service.contracts';
import { IHttpPromise } from 'angular';

export class SquareService {
  static $inject = ['httpservice', '__env'];

  constructor(
    private httpService: HttpService,
    private __env: Environment,
  ) { }

  async getSquareAppScreenerInfo(reset: boolean = false) {
    return Utils.convertCamelCasedObjectToPascalCasedObject(
      await squareService.getSquareAppScreenerInfo(reset),
    );
  }

  async checkSquareNameIsDuplicate(name: string) {
    return this.httpService.get<CheckSquareNameIsDuplicateResponse>({
      url: '/SquareService/CheckSquareNameIsDuplicate',
      params: { name },
    });
  }

  async getSquareDetails(squareGuid: string) {
    return this.httpService.get<SquareDetailItemResponse>({
      url: '/SquareService/SelectSquare',
      params: { guid: squareGuid },
    });
  }

  async toggleSquareArchive(squareGuid: string) {
    return (await this.httpService.post<number>({
      url: '/SquareService/ToggleSquareArchive',
      data: { squareGuid },
    })).data;
  }

  async getSquareCountries(squareGuid = null) {
    return (await this.httpService.get<CountryResponse[]>({
      url: '/SquareService/GetSquareCountries',
      params: { squareGuid },
    })).data;
  }

  async getThemes() {
    return (await this.httpService.get<ActivityThemeListItemResponse[]>({
      url: '/SquareService/GetThemes',
    })).data;
  }

  async getThemesForActivityConfig(activityGuid: string) {
    return (await this.httpService.get<ActivityThemeListItemResponse[]>({
      url: '/SquareService/GetThemesForActivityConfig',
      params: { activityGuid },
    })).data;
  }

  async getThemesForDropdown() {
    return (await this.httpService.get<ActivityThemeListItemResponse[]>({ url: '/SquareService/GetThemeList' })).data;
  }

  async updateSquare(square: UpdateSquare) {
    const request: UpdateSquareRequest = {
      ...square,
      Language: square.Language.Code,
    };

    return (await this.httpService.post<UpdateSquareResponse>({
      url: '/SquareService/UpdateSquare',
      data: request,
    })).data;
  }

  async updateLastSelectedDate(squareGuid: string) {
    return this.httpService.post<void>({ url: '/SquareService/UpdateLastSelectedDate', params: { squareGuid } });
  }

  async createSquare(squareName: string, programGuid: string, clonedSquareGuid: string) {
    return this.httpService.post<CreateSquareResponse>({
      url: '/SquareService/CreateSquare',
      data: {
        name: squareName,
        programGuid,
        clonedSquareGuid,
      },
    });
  }

  async getProgramSquares(programGuid: string) {
    return this.httpService.get<ListSquareItemResponse>({
      url: '/SquareService/ListSquare',
      params: {
        guid: programGuid,
      },
    });
  }

  async getSquareConnectProject(squareGuid: string) {
    return (await this.httpService.get<ConnectProjectDetailItem>({
      url: '/SquareService/GetSquareConnectProject',
      params: { squareGuid },
    })).data;
  }

  async getSquareSelectionForDropdown(programGuid: string = null) {
    return (await this.httpService.get<SquareSelectionResponse>({
      url: '/SquareService/SquareSelection',
      params: { programGuid },
    })).data.List;
  }

  async getSquareListForObserverOrModerator() {
    return (await this.httpService.get<ListSquareItemResponse>({
      url: '/SquareService/GetSquareListForObserverOrModerator',
    })).data.List;
  }

  syncPage(pageTypeName) {
    return async (page) => (await this.httpService.post<SyncPageResponse>({
      url: `/SquareService/Sync${pageTypeName}Page`,
      data: page,
    })).data;
  }

  async saveSquareColors(squareColors: SaveSquareColorsRequest) {
    return (await this.httpService.post<void>({
      url: '/SquareService/SaveSquareColors',
      data: squareColors,
    })).data;
  }

  async saveSquareImages(images) {
    return await this.httpService.upload({
      url: '/SquareService/SaveSquareImages',
      data: images,
    });
  }

  async deleteDesignImages(imageNames: string[]) {
    return (await this.httpService.post<void>({
      url: '/SquareService/DeleteDesignImages',
      data: { imageNames },
    })).data;
  }

  async selectSquareDesign(squareGuid: string) {
    return this.httpService.get<SquareDesignDetailItemResponse>({
      url: '/SquareService/SelectSquareDesign',
      params: { squareGuid },
    });
  }

  async getSquareActivationQuestions(squareGuid: string) {
    return this.httpService.get<GetSquareActivationQuestionsResponse>({
      url: '/SquareService/GetSquareActivationQuestions',
      params: { squareGuid },
    });
  }

  async setSquareActivationQuestions(data: SaveSquareActivationQuestionsRequest) {
    return this.httpService.post<void>({
      url: '/SquareService/SaveActivationQuestions',
      data,
    });
  }

  async getSquareParticipantForLoggedInInsitesUser() {
    return (await this.httpService.get<SquareParticipantForLoggedInInSitesUserResponse>({
      url: '/SquareService/GetSquareParticipantForLoggedInInSitesUser',
    })).data;
  }

  async getSquareUrlWithIdeaStreamProjectId(ideaStreamUrl: string) {
    return this.httpService.get<string>({
      url: '/SquareService/GetUrlWithIdeaStreamProjectId',
      params: { url: ideaStreamUrl },
    });
  }

  async toggleSquarePin(rootUrl: string, guid: string, clientGuid: string) {
    return (await this.httpService.post<void>({
      url: '/SquareService/ToggleSquarePin',
      data: { squareGuid: guid, clientGuid },
    }, rootUrl)).data;
  }

  async saveSquareCustomCSS(css: string, isEmail: boolean) {
    return (await this.httpService.post<void>({
      url: '/SquareService/SaveSquareCustomCSS',
      data: { customCss: css, isEmail },
    })).data;
  }

  async getSquareCustomCSS(isEmail: boolean) {
    return (await this.httpService.get<string>({
      url: '/SquareService/GetCustomCSS',
      params: { isEmail },
    })).data;
  }

  async getSquareSplashData() {
    return (await this.httpService.get<SplashMessageDetailItem>({
      url: '/SquareService/GetSplashDataToEdit',
    })).data;
  }

  async getSplashData() {
    return Utils.convertCamelCasedObjectToPascalCasedObject((await this.httpService.get<SplashMessageDetailItem>({
      url: '/api/SplashMessage/GetNewestSplashMessage',
    }, this.__env.memberApiUrl)).data);
  }

  async saveSquareSplashData(splashData: PascalCase<SaveSplashMessageRequest>, useMemberService: boolean = false, firstMessage: boolean = false) {
    return await squareService.saveSquareSplashData(
      Utils.convertPascalCasedObjectToCamelCasedObject(splashData),
      useMemberService,
      firstMessage,
    );
  }

  async GetImpersonateUserList() {
    return (await this.httpService.get<ImpersonateUserListResponse[]>({
      url: '/SquareService/GetImpersonateUserList',
    })).data;
  }

  async getSquareLanguageDirection() {
    return (await this.httpService.get<boolean>({
      url: '/SquareService/GetSquareLanguageDirection',
    })).data;
  }

  async GetSsoIv() {
    return (await this.httpService.get<string>({
      url: '/SquareService/GetSsoIv',
    })).data;
  }

  async GetSsoKey() {
    return (await this.httpService.get<string>({
      url: '/SquareService/GetSsoKey',
    })).data;
  }

  async deleteSquare(squareGuid: string) {
    return (await this.httpService.post<void>({
      url: '/SquareService/DeleteSquare', params: { squareGuid },
    })).data;
  }

  async getSquareJobIds() {
    return (await this.httpService.get<ListJobItemResponse>({
      url: '/SquareService/SelectionSquareJob',
    })).data.List;
  }

  async getSquareJobs(programGuid) {
    return (await this.httpService.get<GetSquareJobsResponse[]>({
      url: '/SquareService/GetSquareJobs',
      params: { programGuid },
    })).data;
  }

  async updateProgramSquareJobs(jobIds) {
    return (await this.httpService.post<UpdateProgramSquareJobsResponse[]>({
      url: '/SquareService/UpdateProgramSquareJobs',
      data: { jobIds },
    })).data;
  }

  getSquaresPerClient(statuses: number[], allEnv: boolean = false, programGuid: string|null = null): Promise<ClientWithSquaresResponse[]> {
    return squareService.getSquaresPerClientAsync(statuses, allEnv, programGuid);
  }

  isOpenRegistrationScreenerActiveForSquare(): IHttpPromise<boolean> {
    return this.httpService.get<boolean>({
      url: '/ActivityService/IsOpenRegistrationScreenerActiveForSquare',
    });
  }

  getSquareLandingPageConfig(): IHttpPromise<SquareLandingPageConfigResponse> {
    return this.httpService.get<SquareLandingPageConfigResponse>({
      url: '/SquareService/GetSquareLandingPageConfig',
    });
  }

  setSquareLandingPageConfig(config: SquareLandingPageConfigResponse): IHttpPromise<boolean> {
    return this.httpService.post<boolean>({
      url: '/SquareService/SetSquareLandingPageConfig',
      data: config,
    });
  }

  getSquareSocialLoginConfig(): IHttpPromise<SquareSocialLoginConfigResponse> {
    return this.httpService.get<SquareSocialLoginConfigResponse>({
      url: '/SquareService/GetSquareSocialLoginConfig',
    });
  }

  setSquareSocialLoginConfig(config: SquareSocialLoginConfigResponse): IHttpPromise<boolean> {
    return this.httpService.post<boolean>({
      url: '/SquareService/SetSquareSocialLoginConfig',
      data: config,
    });
  }

  getUserMigrationConfig(): IHttpPromise<UserMigrationConfigResponse> {
    return this.httpService.get<UserMigrationConfigResponse>({
      url: '/SquareService/GetUserMigrationConfig',
    });
  }

  saveUserMigrationConfig(config: UserMigrationConfigResponse): IHttpPromise<boolean> {
    return this.httpService.post<boolean>({
      url: '/SquareService/SaveUserMigrationConfig',
      data: config,
    });
  }

}
