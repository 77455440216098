'use strict';

import './../styles/site.scss';
import * as angular from 'angular';

let env: Environment;
const anyWindow: any = window;

// Import variables if present (from env.js)
if (anyWindow) {
  env = angular.copy(anyWindow.__env);

  env.forAllEnvironments = async (callback) => {
    await Promise.all(env.environments.map((environment) => callback(environment)));
  };
}

angular
  .module('env', [])
  .constant('__env', env)
  .constant('authenticationUrl', `${env.authenticationBase}?return_uri=${encodeURIComponent(window.location.href)}`);

angular
  .module('insitesApp', [
    'env',
    'insitesApp.core',
    'insitesApp.layout',
    'insitesApp.home',
    'insitesApp.activation',
    'insitesApp.platformnotice',
    'insitesApp.featureSetting',
    // 'insitesApp.landing',
    'insitesApp.clientinfo',
    'insitesApp.clientprograms',
    'insitesApp.clientMaintenance',
    'insitesApp.loginerror',
    // 'insitesApp.clientnetworks',
    'insitesApp.programinfo',
    'insitesApp.programsquares',
    // 'insitesApp.programorphans',
    // 'insitesApp.networkinfo',
    'insitesApp.squareinfo',
    'insitesApp.squarecommunication',
    'insitesApp.squareconfig',
    // 'insitesApp.orphaninfo',
    // 'insitesApp.orphanoverview',
    'insitesApp.squareForumRoom',
    'insitesApp.squaremembers',
    'insitesApp.ssoideastream',
    'insitesApp.activityconfig',
    'insitesApp.activityQualResearchConfig',
    'insitesApp.activityInfo',
    'insitesApp.activityData',
    'insitesApp.activityHealth',
    'insitesApp.squareActivities',
    'insitesApp.squarehealth',
    'insitesApp.squarerewards',
    'insitesApp.workarounds',
    'insitesApp.log',
    'insitesApp.exportlog',
    'insitesApp.config',
    'insitesApp.iscComponents',
    'insitesApp.activityWizardType',
    'insitesApp.activityFilter',
    'insitesApp.activityStatusFilter',
    'insitesApp.segmentationStatusFilter',
    'insitesApp.squareStatusFilter',
    'insitesApp.dateRange',
    'insitesApp.basicActivityFilter',
    'insitesApp.healthFilter',
    'insitesApp.memberFilter',
    'insitesApp.segmentsFilter',
    'insitesApp.segmentationsFilter',
    'insitesApp.themeEditor',
    'insitesApp.researcherLogin',
    'insitesApp.squareLogout',
    'insitesApp.squarecommunicationWizard',
    'insitesApp.clientConfiguration',
    'insitesApp.communicationStatusFilter',
    'insitesApp.rewardHandling',
    'insitesApp.reporting',
    'insitesApp.iscMediaGallery',
    'insitesApp.iscPulse',
    'insitesApp.admin404Page',
    'insitesApp.manuallyHandledRewardFilter',
    'insitesApp.squareJobStatusFilter',
    'insitesApp.consent',
  ]);
