'use strict';

import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { RouterHelper } from '../../../blocks/router/router-helper';
import { SocialLoginController } from './sociallogin.controller';
import { ServerConstants } from 'src/app/core/serverconstants';

const template = require('./sociallogin.html');

const routeConfig = (routerHelper: RouterHelper,
  $stateParams,
  serverConstants: ServerConstants,
  featureservice: FeatureService) => {
  routerHelper.configureStates([{
    state: 'root.square.sociallogin',
    config: {
      url: '/config/sociallogin',
      templateUrl: template,
      controller: SocialLoginController,
      controllerAs: 'vm',
      title: 'Social login',
      settings: {
        nav: 2,
        childnav: 8,
        label: 'Social login',
        showForums: true,
        show: async () => await hasAccessToSocialLogin(),
      },
      disallowRoles: ['Observer'],
    },
  }]);

  const hasAccessToSocialLogin = async (): Promise<boolean> => featureservice.checkFeatureAccessibilityForSetup(
    serverConstants.featureConstants.azureAdB2CLogin);

};

routeConfig.$inject = ['routerHelper', '$stateParams', 'serverConstants', 'featureservice', 'squareservice'];

export default routeConfig;
