'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from './../../../../../../core/serverconstants';
import * as _ from 'lodash';

import { CtaValidation } from './../common/ctaValidation';
import { DateFormatService } from './../../../../../../core/services/dateformat.service';
import { CommunicationService } from '../../../../../../core/dataservices/communication.service';
import { ConfigurationService } from '../../../../../../core/dataservices/configuration.service';
import { IFileConfigurationResponse } from '../../../../../../core/contracts/configuration.contracts';

export class NewsletterController {
  static $inject = ['dateFormatService', 'serverConstants', 'communicationservice', '$scope',
    'ctaValidation', '$stateParams', 'logger', 'iscConfigurationService'];

  constructor(
    private dateFormatService: DateFormatService,
    private serverConstants: ServerConstants,
    private communicationservice: CommunicationService,
    private $scope: ng.IScope,
    private ctaValidation: CtaValidation,
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
    private iscConfigurationService: ConfigurationService,
  ) { }

  wizardStep;
  channelType;
  form;
  removeLink;
  validationConstants = this.serverConstants.validationConstants;
  minDateTime = this.dateFormatService.startOfDay();
  model = {
    Guid: '',
    CallToActionText: '',
    CallToActionUrl: 'https://',
    EditCTAUrl: 'https://',
    Title: '',
    Overview: '',
    Message: '',
    StartDateTime: DateTime.now(),
    StartTime: DateTime.now(),
    StartDateOffset: DateTime.local().offset / 60,
    ChannelType: 0,
  };
  prefixLabel;
  suffixLabel;
  imageStimuliMaxSizeBytes: number;
  fileTypeConstants;
  fileConfiguration: IFileConfigurationResponse[] = [];
  acceptedMimeTypes: string;
  acceptedPatterns: string;
  acceptedExtensions: string;

  invalidFiles = [];
  private ctaValidationSubscription: () => void;
  communication;
  isReadOnly;
  saveNotApplicable = false;
  saveCallback = () => this.saveData(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm();
  messageChangeSubscription = _.noop;
  dateChangeSubscription = _.noop;
  channelChangeSubscription = _.noop;

  $onInit() {
    this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
    this.iscConfigurationService.getFileConfiguration()
      .then((response) => {
        this.fileConfiguration = response;
        this.imageStimuliMaxSizeBytes = _.find(this.fileConfiguration, (config) =>
          config.FileType === this.fileTypeConstants.image).MaxFileSizeMb * Math.pow(1024, 2);

        const { mimeTypes, patterns, extensions } =
          this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image]);
        this.acceptedMimeTypes = mimeTypes.toString();
        this.acceptedPatterns = `'${patterns.toString()}'`;
        this.acceptedExtensions = extensions.toString();
      });

    this.removeLink = this.wizardStep.linkComponent(`channel-${this.channelType}`, this);
    this.messageChangeSubscription = this.$scope.$watch('vm.model.Message', () => {
      // Clear invalid file warnings on action
      this.invalidFiles = [];
      // Get the newsletter image
      const newsletterImage = document.getElementById('editorImage');
      // Get the newsletter image table container
      const imageContainer = document.getElementById('imageContainer');
      // Check if the newsletter image is present in the text, exit if found
      if (newsletterImage !== null) {
        return;
      }
      // Check if an image container(table) is still present after the image was deleted
      if (imageContainer === null) {
        return;
      }
      // Remove said container
      imageContainer.parentNode.removeChild(imageContainer);
    });

    this.dateChangeSubscription = this.$scope.$watchGroup(['vm.model.StartDateTime', 'vm.model.StartDateOffset'], () => {
      this.timeChanged();
    });

    this.channelChangeSubscription = this.$scope.$watchGroup(['vm.communication'], () => {
      // We will know about the prefix and suffix only after the newsletter channel has been selected
      const data: any = this.communication && this.communication.Channels
        ? this.communication.Channels[0]
        : undefined;
      if (data !== undefined) {
        this.prefixLabel = data.ChannelPrefixTemplate;
        this.suffixLabel = data.ChannelSuffixTemplate;
      }
    });

    this.ctaValidationSubscription = this.ctaValidation.setupValidation(
      this.$scope,
      () => this.form.CallToActionText,
      () => this.form.EditCTAUrl);
    this.$loadData(this.communication);
  }

  $onDestroy() {
    this.removeLink();
    this.ctaValidationSubscription();
    this.messageChangeSubscription();
    this.dateChangeSubscription();
    this.channelChangeSubscription();
  }

  $wizardStepIsCompleted() {
    return this.form ? this.form.$valid : false;
  }

  $wizardIsValid() {
    if (!_.isUndefined(this.form)) {
      return this.form.$valid;
    }
    return true;
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    if (!this.isReadOnly && this.communication.Channels[0].Channel.IsPublished) {
      return '';
    }
    return this.serverConstants.squareConstants.wizardNotPublishedStatus;
  }

  $wizardBeforeNext() {
    this.saveData(this);
  }

  $wizardStepLoaded() {
    const data = this.communication.Channels[0];
    if (data !== undefined && this.model.Guid !== undefined) {
      this.model.Guid = data.Channel.Guid;
    }
  }

  resetForm() {
    this.$loadData(this.communication);
    this.form.$setPristine();
  }

  async validateNewsletterImage($newFiles, $invalidFiles) {
    if (!$newFiles) {
      return;
    }

    for (const file of $newFiles) {
      this.invalidFiles = [];

      if (file.size > this.imageStimuliMaxSizeBytes) {
        file.$errorMessages = file.$errorMessages || {};
        file.$errorMessages.maxSize = true;
        this.invalidFiles.push(file);
        $invalidFiles.splice(0);
      } else if (file.$errorMessages) {
        this.invalidFiles.push(file);
        $invalidFiles.splice(0);
      }
    }
  }

  saveData(root: this) {
    const request = {
      Channel: {
        Guid: root.model.Guid,
        CommunicationGuid: root.communication.Communication.Guid,
        Title: root.model.Title,
        ChannelType: root.channelType,
        Message: _.escape(root.model.Message),
        CallToActionText: root.model.CallToActionText,
        CallToActionUrl: root.model.CallToActionText ? root.model.EditCTAUrl : '',
        EditUrl: root.model.EditCTAUrl,
        StartDateTime: root.dateFormatService.getDateTimeForSaving(root.model.StartDateTime, root.model.StartDateOffset),
        StartDateOffset: root.model.StartDateOffset,
      },
    };

    const arg: { request: any } = { request: angular.toJson(request) };
    root.form.$setPristine();
    return root.communicationservice.updateEngagement(arg).then(() => {
      root.communicationservice.selectCommunication(root.$stateParams.communicationGuid).then((response) => {
        root.communication = response;
        root.$loadData(response);
      });
      if (!root.isReadOnly) {
        root.logger.success('Communication message successfully updated.');
      }
    }, () => {
      root.logger.error('Communication message not successfully updated.');
    });
  }

  $loadData(communication: any): void {
    const data: any = communication.Channels[0];
    if (data !== undefined) {
      this.prefixLabel = data.ChannelPrefixTemplate;
      this.suffixLabel = data.ChannelSuffixTemplate;
      this.model.Guid = data.Channel.Guid;
      this.model.Title = communication.Communication.Title;
      if (data.Channel.Title !== undefined && data.Channel.Title !== '') {
        this.model.Title = data.Channel.Title;
      }

      this.model.CallToActionText = data.Channel.CallToActionText;
      this.model.CallToActionUrl = data.Channel.CallToActionText ? data.Channel.CallToActionUrl : this.model.CallToActionUrl;
      this.model.EditCTAUrl = data.Channel.CallToActionText ? data.Channel.EditUrl : this.model.EditCTAUrl;
      const startDate = this.isReadOnly ?
        DateTime.fromISO(data.Channel.StartDateTime) :
        DateTime.max(...[DateTime.now(), data.Channel.StartDateTime ? DateTime.fromISO(data.Channel.StartDateTime) : DateTime.now()]);

      this.model.StartDateTime = this.dateFormatService.getDateTime(startDate || DateTime.now(),
        data.Channel.StartDateOffset || this.model.StartDateOffset);
      this.model.StartTime = this.model.StartDateTime;
      if (this.model.StartDateTime) {
        this.minDateTime = this.dateFormatService.startOfDay(this.dateFormatService.getMinDate(DateTime.now(), this.model.StartDateTime));
      }

      this.model.StartDateOffset = data.Channel.StartDateOffset || this.model.StartDateOffset;
      this.model.Message = data.Channel.Message;
      this.model.ChannelType = data.Channel.ChannelType;
      this.saveNotApplicable = this.communication.Channels[0] && this.communication.Channels[0].Channel.IsPublished;
    } else {
      this.model.Title = communication.Communication.Title;
    }
  }

  timeChanged(): void {
    if (!this.isReadOnly && this.model.StartDateTime) {
      const communicationStartDateTime = this.dateFormatService.getDateTime(this.model.StartDateTime, this.model.StartDateOffset);
      const currentDateTime = DateTime.now();
      this.form.dateField.$setValidity('dateInPast', communicationStartDateTime >= (currentDateTime.set({ second: 0, millisecond: 0 })));
      this.form.dateField.$dirty = true;
      this.form.dateField.$pristine = false;
    }
  }

  private startDateTimeChange() {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
    }
  }

}
